@import './colors';
.fatal-error-container{
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 900;
  .fatal-error-background{
    background:$main-alternate-color;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .fatal-error-content{
    color:white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
