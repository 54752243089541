#rpi-alert-container{
  position: absolute;
  z-index: 100;
  right:10px;
  &.fix{
    position: fixed;
    top: 10px;
  }
  .notification-item{
    &.closing{
      opacity: 0;
      transition: 0.5s all ease;
    }
  }
}
