#sign-in-form {
  .container {
    display: flex;
    text-align: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
  }

  .form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
    text-align: center;
  }

  .form-control {
    margin-bottom: 10px;
  }
}
